(function() {

    var $helpToggle = $('[data-toggle=help]'),
        $helpClose = $('[data-dismiss=help]');
    
    $helpToggle.on("click", function() {
        var $helpModal = $($(this).data('target'));
        $helpModal.addClass('show');

        // Does the form already exist?
        if ($("#supadd").is(":visible")) {
            ; // Nothing to do
        } else {
            // Initialise the help
            agentcall('ui/help.ajax.php', 'loadHelp', 'v2', window.location.href);
        }
    });

    $helpClose.on("click", function() {
        var $helpModal = $(this).closest('.help-modal');
        $helpModal.removeClass('show');
    });

})();


function loadHelp() {
    agentcall('ui/help.ajax.php', 'loadHelp', 'v2', window.location.href);
}



// Is there anything in the current help form that we need to save?
$(window).on("bind", 'beforeunload', function(){
    if (!supportsLocalStorage()) { return false; }
    // Is the help window open?
    if ($("#supadd").length >= 1) {
    // if ($(".help-modal").hasClass("show")) {
        // Restore the html first
        localStorage.setItem("sobs.supadd.state", $("#helpContent").html());
        // Is the form open
        if ($("#supadd")) {
            $parms = form_to_array("supadd");
            localStorage.setItem("sobs.supadd.parms", JSON.stringify($parms));
        }
    } else {
        localStorage.removeItem("sobs.supadd.state");
        localStorage.removeItem("sobs.supadd.parms");

    }
});



$(document).on("ready", function() {
    if (!supportsLocalStorage()) { return false; }
    $html = localStorage.getItem("sobs.supadd.state");
    if ($html) {
        $(".help-modal").collapse("show");
        $("#helpContent").html($html);
        $parms = JSON.parse( localStorage.getItem("sobs.supadd.parms") );
        array_to_form("supadd", $parms);

        localStorage.removeItem("sobs.supadd.state");
        localStorage.removeItem("sobs.supadd.parms");

        if ($("#supadd").length > 0) {
            validateSupadd();
        }
    }
});

function testSave() {
    if ($(".help-modal").hasClass("show")) {
        // Is the form open
        if ($("#supadd")) {
            $parms = form_to_array("supadd");
            localStorage.setItem("sobs.supadd.parms", JSON.stringify($parms));
            console.log( localStorage.getItem("sobs.supadd.parms"));
            // localStorage.setItem("sobs.supadd.staffname", $("#gm_staffname").val());
            localStorage.setItem("sobs.supadd.state", $("#helpContent").html());
        }
    }

}


function testRestore() {
    console.log("testRestore");
    $html = localStorage.getItem("sobs.supadd.state");
    if ($html) {
        console.log("we have some html, now show the dialog");
        $(".help-modal").collapse("show");
        console.log("set the html content");
        $("#helpContent").html($html);
        console.log("get the parms");
        $parms = JSON.parse( localStorage.getItem("sobs.supadd.parms") );
        console.log("send the parms back to the form");
        array_to_form("supadd", $parms);
        // $("#gm_schoolname").val( localStorage.getItem("sobs.supadd.schoolname") );
        // $("#gm_staffname").val( localStorage.getItem("sobs.supadd.staffname") );
        console.log("We are dropping the localStorage data");
        localStorage.removeItem("sobs.supadd.state");
        localStorage.removeItem("sobs.supadd.parms");
        // localStorage.removeItem("sobs.supadd.schoolname");
        // localStorage.removeItem("sobs.supadd.staffname");
    } else {
        console.log("html = " + $html);
    }
}




// Add a support entry
function supAdd() {
    agentcall('ui/help.ajax.php', 'supAdd', 'v2');
}

// Add a support entry
function supNew() {
    agentcall('ui/help.ajax.php', 'supNew', 'v2');
}


// Validation on the support form
function validateSupadd() {

    $(".schoolname").on("change", function() {
        agentcall('ui/help.ajax.php', 'selectSchoolName', 'v2', $("#gm_schoolname").val());
    });

    $(".staffname").on("change", function() {
        agentcall('ui/help.ajax.php', 'selectStaffName', 'v2', $("#gm_staffname").val());
    });

    $(".appSelected").on("click", function() {
        agentcall('ui/help.ajax.php', 'appSelected', 'v2', $(this).find("input").attr("name"));
    });


    // In order to validate a date we need to process hidden fields
	$.validator.setDefaults({
		ignore: ".ql-container *"
	});

    var valid = {};
    valid.rules = {
        gm_schoolname:      {   required:	true },
        gm_title:           {   required:   true },
        type:               {   required:   true,
                                min:        1 }
        };
    valid.messages = {
            gm_schoolname:  {	required:  	"A schoolname is required (141)" },
            gm_title:       {   required:   "A title is required (142)" },
            type:           {   required:   "A type is required (145)",
                                min:        "A type must be selected (147)"}
        };
    $("#supadd").validate(valid);    
}   


// Refresh the list of staff
function freshStaffList($slist) {
	var $el = $(".gm_staffname");
	$el.empty(); // remove old options
	$.each($slist, function($id, $text) {
		$el.append($("<option></option>").attr("value", $text));
	});
}

// And allow us to go straight to an app
function activateApplication($schoolid, $appid) {
	agentcall('ui/schools.ajax.php', 'activateApplication', 'v2', $schoolid, $appid);
}

function supSave($supid) {
    $parms = form_to_array("supadd");
    agentcall('ui/help.ajax.php', 'supSave', 'v2', $supid, $parms, window.location.href);
}





// Open an existing support entry
function supView($supid) {
	agentcall('ui/help.ajax.php', 'supView', 'v2', $supid);
}

function supper($supid) {
	agentcall('ui/help.ajax.php', 'supper', 'v2', $supid);
}

function monSave($supid) {
    $parms = form_to_array("supadd");
    agentcall('ui/help.ajax.php', 'monSave', 'v2', $supid, $parms, window.location.href);
}





function helpPop($videoid) {
    agentcall('ui/help.ajax.php', 'helpPop', 'v2', $videoid);
}


function helpAdd($helpid) {
    agentcall('ui/help.ajax.php', 'helpAdd', 'v2', $helpid);
}

function helpEdit($reqid) {
    agentcall('ui/help.ajax.php', 'helpEdit', 'v2', $reqid);
}

function validateHelpfrm() {

    $("#gm_format").on("change", function() {
		if ($(this).val() == "1") { // Video
			$(".showvideo").parent().toggle(true);
			$(".showarticle").parent().toggle(false);

        } else if ($(this).val() == "2") { // Article
			$(".showvideo").parent().toggle(false);
			$(".showarticle").parent().toggle(true);
		}
	});

}

function helpSave($reqid) {
    $parms = form_to_array("helpfrm");
    agentcall('ui/help.ajax.php', 'helpSave', 'v2', $reqid, $parms);
}



function helpRequired($helpid) {
    agentcall('ui/help.ajax.php', 'helpRequired', 'v2', $helpid);
}



/**
 * List this article / video in more locations
 */
function addLocations($reqid) {
    agentcall('ui/help.ajax.php', 'addLocations', 'v2', $reqid);
}

function saveLocations($reqid) {
    $parms = form_to_array("addlocs");
    agentcall('ui/help.ajax.php', 'saveLocations', 'v2', $reqid, $parms);
}




/**
 * This is our maintenance of the help information
 * List the outstanding requests
 */
function helpSatisfy($requests) {
    agentcall('ui/help.ajax.php', 'helpSatisfy', 'v2', $requests);
}


// Delete a request
function helpDelete($reqid) {
	gmConfirm("Are you sure you want to delete this request?", "javascript:helpDelete2(" + $reqid + ")");
}

function helpDelete2($reqid) {
    agentcall('ui/help.ajax.php', 'helpDelete2', 'v2', $reqid);
}



// Push this help out to the production servers
function pushHelp($reqid) {
    agentcall('ui/help.ajax.php', 'pushHelp', 'v2', $reqid);
}

// Pull requests on the production servers to my dev machine - finish by listing the requests
function pullHelp() {
    agentcall('ui/help.ajax.php', 'pullHelp', 'v2');
}




