(function() {

	function trapResponsiveTable() {
		$('.table-responsive').responsiveTable();
	}

	function trapSortableTable(onDrop) {
		// docs: https://github.com/SortableJS/Sortable
		$('.table-sortable > tbody').sortable({
			sort: true,
			handle: '.sort-handle',
			animation: 150,
			onStart: function (e) {
				$(e.item).closest('.table-sortable').addClass('dragging');
			},
			/**
			 * Event triggered when dragging ends
			 * 
			 * @param {
			 * 	 ...Event
			 *   item: HTMLElement (item being dragged)
			 *   oldIndex: number
			 *   newIndex: number
			 * } event
			 */
			onEnd: function (e) {
				$(e.item).closest('.table-sortable').removeClass('dragging');

				if (onDrop) {
					onDrop(e)
				}
			}
		});
	}

	function trapActionMenu() {
		$("button.btn").on("click", function( $event ) {
            // console.log("click triggered!");
			$parent = $(this).parent();
			$parent.toggleClass("show");

            $expanded = $(this).attr("aria-expanded");
            if ($expanded == "true")
                $(this).attr("aria-expanded", "false");
            else
                $(this).attr("aria-expanded", "true");

            $event.stopPropagation();
		});

		$("button.btn").on("keydown", function( $event ) {
            // console.log("enter triggered!");
			$event.stopPropagation();
		});

	}

	window.trapResponsiveTable = trapResponsiveTable;
	window.trapSortableTable = trapSortableTable;
	window.trapActionMenu = trapActionMenu;

})();