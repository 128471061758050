// Support for the client's supmon page

function response($supid) {
    agentcall('ui/monsup.ajax.php', 'response', 'v2', $supid);
}

function validateMonitoradd() {

    $(".cbopen").on("change", function() {
        $(".reopen").parent().toggle( $(this).val() == 1 );
    });

    $(".cancel").on("click", function() {
        window.close();
    });

    $(".statusupdate").on("change", function() {
        $status = $("#gm_sup_status").val();
        if ($status == 3) { // Have we just selected STATUS_WAITUSER
            $(".waitfield").parent().toggle(true);
        } else {
            $(".waitfield").parent().toggle(false);
        }
    });
}

function monsupSave($supid) {
    $parms = form_to_array("monitoradd");
    agentcall('ui/monsup.ajax.php', 'monsupSave', 'v2', $supid, $parms);
}




