
// We need some JS to support managing the LISS calls

function showLiss($clickable, $number) {
	// $number can be an import or an export number
	agentcall('liss/liss.ajax.php', 'showLiss', 'v2', $clickable, $number);
}



function doLiss($lissid) {
	gmAlert("We re missing a doLiss() function for this LISS connection", "LISS missing function");
}


// Initiate a local action
function lissExecute($lissid, $importNumber) {
	agentcall('liss/liss.ajax.php', 'lissExecute', 'v2', $lissid, $importNumber);
}



function editLiss( $lissid ) {
	agentcall('liss/liss.ajax.php', 'editLiss', 'v2', $lissid);
}
function saveLiss( $lissid ) {
    $parms = form_to_array("liss");
	agentcall('liss/liss.ajax.php', 'saveLiss', 'v2', $lissid, $parms);
}

function deleteLiss( $lissid ) {
	gmConfirm("Are you sure you want to delete this LISS connection?", "javascript:deleteLiss2(" + $lissid + ")", "javascript:showLiss()");
}
function deleteLiss2($lissid) {
	agentcall('liss/liss.ajax.php', 'deleteLiss2', 'v2', $lissid);
}



// Timetable parameters
function publishTimetableJsonParms2() {
    $parms = form_to_array("ttparms");
	agentcall('liss/liss.ajax.php', 'publishTimetableJsonParms2', 'v2', $parms);
}

function publishDailyDeltasJsonParms2() {
    $parms = form_to_array("ddparms");
	agentcall('liss/liss.ajax.php', 'publishDailyDeltasJsonParms2', 'v2', $parms);
}


// Resolve conflicts
function resolve1() {
	agentcall('liss/liss.ajax.php', 'resolve1', 'v2');
}

// $actions["resolveMoveConflict($coid)"] = "Move to another room";
// $actions["resolveDeleteConflict($coid)"] = "Delete this imported booking";
// $actions["resolveDeleteRbo($rboid)"] = "Delete this displaced booking";
// $actions["resolveRestoreRbo($rboid)"] = "Restore this displaced booking";
// $actions["resolveMoveRbo($rboid)"] = "Move to another room";

function resolveDeleteConflict($coid) {
	agentcall('liss/liss.ajax.php', 'resolveDeleteConflict', 'v2', $coid);
}
function resolveDeleteRbo($rboid) {
	agentcall('liss/liss.ajax.php', 'resolveDeleteRbo', 'v2', $rboid);
}
function resolveRestore1Rbo($rboid) {
	agentcall('liss/liss.ajax.php', 'resolveRestore1Rbo', 'v2', $rboid);
}
function resolveRestore2Rbo($rboid) {
	agentcall('liss/liss.ajax.php', 'resolveRestore2Rbo', 'v2', $rboid);
}
function resolveMoveConflict($coid) {
	agentcall('liss/liss.ajax.php', 'resolveMoveConflict', 'v2', $coid);
}
function resolveMoveConflict2($coid) {
    $parms = form_to_array("rmc2");
	agentcall('liss/liss.ajax.php', 'resolveMoveConflict2', 'v2', $coid, $parms);
}
function resolveMoveRbo($rboid) {
	agentcall('liss/liss.ajax.php', 'resolveMoveRbo', 'v2', $rboid);
}
function resolveMoveRbo2($rboid) {
    $parms = form_to_array("rmrbo2");
	agentcall('liss/liss.ajax.php', 'resolveMoveRbo2', 'v2', $rboid, $parms);
}





function incomingDelete($incid) {
	agentcall('liss/liss.ajax.php', 'incomingDelete', 'v2', $incid);
}



// We have a CSV file, convert this to JSON for delayed processing
function convertCsvToJson($lissid) {
	agentcall('liss/liss.ajax.php', 'convertCsvToJson', 'v2', $lissid);
}





// Get teachers
function getTeachersJsonSave2($incid) {
    $parms = form_to_array("gtjs");
	agentcall('liss/liss.ajax.php', 'getTeachersJsonSave2', 'v2', $incid, $parms);
}




