// Support for the monitor page 

function statusOpen($msg, $class) {
    $msg = $msg || null;
    $class = $class || null;
    agentcall('ui/monitor.ajax.php', 'statusOpen', 'v2', $msg, $class);
}

function today($msg, $class) {
    $msg = $msg || null;
    $class = $class || null;
    agentcall('ui/monitor.ajax.php', 'today', 'v2', $msg, $class);
}

function statusWaiting($msg, $class) {
    $msg = $msg || null;
    $class = $class || null;
    agentcall('ui/monitor.ajax.php', 'statusWaiting', 'v2', $msg, $class);
}

function statusDeferred($msg, $class) {
    $msg = $msg || null;
    $class = $class || null;
    agentcall('ui/monitor.ajax.php', 'statusDeferred', 'v2', $msg, $class);
}

function statusClosed($msg, $class) {
    $msg = $msg || null;
    $class = $class || null;
    agentcall('ui/monitor.ajax.php', 'statusClosed', 'v2', $msg, $class);
}

function statusArchived($msg, $class) {
    $msg = $msg || null;
    $class = $class || null;
    agentcall('ui/monitor.ajax.php', 'statusArchived', 'v2', $msg, $class);
}

// Add a support entry
function fresh($msg, $class) {
    $msg = $msg || null;
    $class = $class || null;
    agentcall('ui/monitor.ajax.php', 'fresh', 'v2', null, $msg, $class);
}

// Ping the server to keep the session open
function monitorRefresh() {
	setTimeout("monitorRefresh2()", 60000); // 1 minute
}

function monitorRefresh2() {
    // Are we still on the monitor fresh page?
    if ($(".monitor-refresh")[0]) {
        agentcall('ui/monitor.ajax.php', 'fresh', 'v2', null, null);
    }
}



function monitorDetail($supid) {
    agentcall('ui/monitor.ajax.php', 'monitorDetail', 'v2', $supid);
}

// For the function validateMonitoradd() - see monsup.js
// For the function validateMonitoradd() - see monsup.js
// For the function validateMonitoradd() - see monsup.js
// For the function validateMonitoradd() - see monsup.js
// For the function validateMonitoradd() - see monsup.js



function monitorSave($supid) {
    $parms = form_to_array("monitoradd");
    agentcall('ui/monitor.ajax.php', 'monitorSave', 'v2', $supid, $parms);
}



// Assign this request to a staff member
function monitorAssign($supid, $userid) {
    agentcall('ui/monitor.ajax.php', 'monitorAssign', 'v2', $supid, $userid);
}


// Add a follower
function addFollower($supid, $userid) {
    agentcall('ui/monitor.ajax.php', 'addFollower', 'v2', $supid, $userid);
}



// Actions for a monitor record
function monitorArchive($supid) {
    agentcall('ui/monitor.ajax.php', 'monitorArchive', 'v2', $supid);
}


// Actions for a monitor record
function monitorDelete($supid) {
    agentcall('ui/monitor.ajax.php', 'monitorDelete', 'v2', $supid);
}

// Delete this record and show the detail for the next one
function deleteNext($supid) {
    agentcall('ui/monitor.ajax.php', 'deleteNext', 'v2', $supid);
}



// Search
function monitorSearch() {
    $parms = form_to_array("search");
    agentcall('ui/monitor.ajax.php', 'monitorSearch', 'v2', $parms);
}




// Become school
function becomeSchool($schoolid) {
    agentcall('ui/monitor.ajax.php', 'becomeSchool', 'v2', $schoolid);
}




// Statistics
function stats() {
    agentcall('ui/monitor.ajax.php', 'stats', 'v2');
}

function security() {
    agentcall('ui/monitor.ajax.php', 'security', 'v2');
}


