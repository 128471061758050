// Generic JS functions used across SOBS

function returnHome() {
    agentcall('ui/schools.ajax.php', 'returnHome', 'v2');
}

function logoffCurrentuser() {
    window.location.assign("/ui/home.php?logoff=true");
}

function selectAnApp() {
    window.location.assign("/ui/login.php");
}

function postLoginApplication(appcode) {
	agentcall('ui/login.ajax.php', 'postLoginApplication', 'v2', appcode);
}

function getSmsApproval() {
	agentcall('ui/login.ajax.php', 'getSmsApproval', 'v2');
}

function validateSmsapprove() {
    // In order to process radio buttons we need to enable hidden fields
	$.validator.setDefaults({
		ignore: ".ql-container *"
	});

	var valid = {};
	valid.rules = {
			gm_approved: { 	required: true },
			gm_approvedby: { required: function() {
                // return true or false depending on whether it's required or not
                return ($('input[name="gm_approved"]').val() == 2);
              } 				
            }
    };
	valid.messages = {
			gm_approved: {	required: "You must select an option" },
			gm_approvedby: { required: "You must supply the name of the approver" }
	};
	$("#smsapprove").validate(valid);
}

function smsapproval($appid) {
	$parms = form_to_array("smsapprove");
	agentcall('ui/login.ajax.php', 'smsapproval', 'v2', $appid, $parms);
}



// When we have finished doing application initialisation stuff this should get called 
function postLoginProcessing() {
	agentcall('ui/login.ajax.php', 'postLoginProcessing', 'v2', null, true);
}



// Ping the server to keep the session open
function sobsKeepAlive() {
	setTimeout("sobsKeepAlive2()", 600000); // 10 minutes
}
function sobsKeepAlive2() {
    agentcall('ui/keepalive.ajax.php', 'keepalive', 'v2');
}


// This is the updated keep alive function
function sobsKeepAlive1() {
    var checkInterval = setInterval(function(){
        // console.log("sobsKeepAlive1: every 1 minute");
        agentcall('ui/keepalive.ajax.php', 'keepalive1', 'v2');
    }, 300000); // 5 minutes
}
function sobsKeepAlivePing() {
    // console.log("sobsKeepAlivePing");
}



// Go somewhere
// function goto($target) {
// 	return array("J", "window.location.assign(\"" + $target + "\")");
// }



// My do nothing function
function donothing() {
}








// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};


// We are starting to use localStorage - let's check it is supported
function supportsLocalStorage() {
    try {
        return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
        return false;
    }
}


