
// When a date field is used we need to schedule this function to run

function gmDateFields() {
    var $dobInputs = $('input[type=dob]'),
        $dateInputs = $('input[type=date]'),
        $dateTimeInputs = $('input[type=datetime-local]'),
        $dateRangeInputs = $('input[type=daterange]'),
        $dateMultiInputs = $('input[type=datemulti]'),
        $timeInputs = $('input[type=time]');
    
    $dobInputs.each(function(i, el) {
        if (window.innerWidth > 1024) {
            new Cleave(el, {
                date: true,
                datePattern: ['d', 'm', 'Y']
            });
        } else {
            $(el).attr('type', 'date')
        }
    });

    $dateInputs.flatpickr({
        altInput: true,
        onDayCreate: function(dObj, dStr, fp, dayElem) {
            if ($(dayElem).attr("tabindex") == -1) {
                $(dayElem).attr("tabindex", 0);
            }
        },
        onOpen: function(selectedDates, dateStr, instance) {
            $(instance.calendarContainer).find(".numInputWrapper span").css("opacity","0.6");
            $year = $(".cur-year");
            if ($year.attr("tabindex") == -1) {
                $year.attr("tabindex", 0);
                $year.attr("aria-label", "Use arrow keys to select the year");
            }
            $months = $(".flatpickr-monthDropdown-months");
            if ($months.attr("tabindex") == -1) {
                $months.attr("tabindex", 0);
                $months.attr("aria-label", "Use the space bar to open the selection and then arrow keys to select the month");
            }
            $months.trigger("focus");
        },
        onReady: function(selectedDates, dateStr, instance) {
            instance.altInput.id = instance.input.id + '_alt';
            instance.altInput.name = 'alt_' + instance.input.name;
            instance.set("minDate", $(instance.input).data("min"));
            instance.set("maxDate", $(instance.input).data("max"));
        }
    
    });

    $dateTimeInputs.flatpickr({
        altInput: true,
        enableTime: true,
        onOpen: function(selectedDates, dateStr, instance) {
            $(instance.calendarContainer).find(".numInputWrapper span").css("opacity","0.6");
        },
        onReady: function(selectedDates, dateStr, instance) {
            instance.altInput.id = instance.input.id + '_alt';
            instance.altInput.name = 'alt_' + instance.input.name;
            instance.set("minDate", $(instance.input).data("min"));
            instance.set("maxDate", $(instance.input).data("max"));
        }
    });

    // $rangeHelp = $.parseHTML("<div><p class='help'><b>HELP:</b> Click on the first date; then click the last date</p></div>");
    // $rangeHelp = '<div><p class="help"><b>HELP:</b> Click on the first date; then click the last date</p></div>';
    $dateRangeInputs.flatpickr({
        altInput: true,
        mode: 'range',
        onDayCreate: function(dObj, dStr, fp, dayElem) {
            if ($(dayElem).attr("tabindex") == -1) {
                $(dayElem).attr("tabindex", 0);
            }
        },
        onOpen: function(selectedDates, dateStr, instance) {
            if ($(instance.calendarContainer).find(".alert").length == 0) {
                $(instance.calendarContainer).append("<div class='alert alert-success' role='alert'><strong>NOTE:</strong> Click on the first date;<br/>then click on the last date</div>");
                $(instance.calendarContainer).find(".numInputWrapper span").css("opacity","0.6");
            }
            $year = $(".cur-year");
            if ($year.attr("tabindex") == -1) {
                $year.attr("tabindex", 0);
                $year.attr("aria-label", "Use arrow keys to select the year");
            }
            $months = $(".flatpickr-monthDropdown-months");
            if ($months.attr("tabindex") == -1) {
                $months.attr("tabindex", 0);
                $months.attr("aria-label", "Use the space bar to open the selection and then arrow keys to select the month");
            }
            $months.trigger("focus");
        },
        onReady: function(selectedDates, dateStr, instance) {
            instance.altInput.id = instance.input.id + '_alt';
            instance.altInput.name = 'alt_' + instance.input.name;
            instance.set("minDate", $(instance.input).data("min"));
            instance.set("maxDate", $(instance.input).data("max"));
        }
    });

    $timeInputs.flatpickr({
        altInput: true,
        enableTime: true,
        noCalendar: true,
        onReady: function(selectedDates, dateStr, instance) {
            instance.altInput.id = instance.input.id + '_alt';
            instance.altInput.name = 'alt_' + instance.input.name;
        }

    });


    $dateMultiInputs.flatpickr({
        altInput: true,
        mode: 'multiple',
        onDayCreate: function(dObj, dStr, fp, dayElem) {
            if ($(dayElem).attr("tabindex") == -1) {
                $(dayElem).attr("tabindex", 0);
            }
        },
        onOpen: function(selectedDates, dateStr, instance) {
            if ($(instance.calendarContainer).find(".alert").length == 0) {
                $(instance.calendarContainer).append("<div class='alert alert-success' role='alert'><strong>NOTE:</strong> Click on a date to select<br/>Click selected date to unselect</div>");
                $(instance.calendarContainer).find(".numInputWrapper span").css("opacity","0.6");
            }
            $year = $(".cur-year");
            if ($year.attr("tabindex") == -1) {
                $year.attr("tabindex", 0);
                $year.attr("aria-label", "Use arrow keys to select the year");
            }
            $months = $(".flatpickr-monthDropdown-months");
            if ($months.attr("tabindex") == -1) {
                $months.attr("tabindex", 0);
                $months.attr("aria-label", "Use the space bar to open the selection and then arrow keys to select the month");
            }
            $months.trigger("focus");
        },
        onReady: function(selectedDates, dateStr, instance) {
            instance.altInput.id = instance.input.id + '_alt';
            instance.altInput.name = 'alt_' + instance.input.name;
            instance.set("minDate", $(instance.input).data("min"));
            instance.set("maxDate", $(instance.input).data("max"));
        }
    });



}



