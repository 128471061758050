(function() {

    // $('[data-toggle=tooltip]').tooltip()
    // $('[data-toggle=help]').tooltip()

    var $modal = $('.modal');

    function onFocusIn(e) {
        if ($modal.get(0).contains(e.target)) {
            $modal.find(':focusable').trigger('focus');
        }
    }

    $modal.on('shown.bs.modal', function () {
        document.addEventListener('focusin', onFocusIn);
    });

    $modal.on('hidden.bs.modal', function () {
        document.removeEventListener('focusin', onFocusIn);
    });

})()