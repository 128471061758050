// JS functions used for the Finder

function editMyYearLevels() {
    agentcall('ui/profile.ajax.php', 'editMyYearLevels', 'v2');
}

function saveMyYearLevels() {
    $parms = form_to_array("yearlevels");
    agentcall('ui/profile.ajax.php', 'saveMyYearLevels', 'v2', $parms);
}




