(function() {

    $.fn.responsiveTable = function() {
        $(this).on('focusin', function() {
            $(this).css('z-index', 1);
        });

        $(this).on('focusout', function() {
            $(this).css('z-index', undefined);
        });

        return this.draggable();
    }

    // $('.table-responsive').responsiveTable();


    $.fn.invisible = function() {
        return this.each(function() {
            // $(this).css("visibility", "hidden");
        });
    };
    $.fn.visible = function() {
        return this.each(function() {
            // $(this).css("visibility", "visible");
        });
    };

    
})();


function tableTrapEnterOnRow() {
    $(".clickable-row").on("keydown", function($event) {
        var $keycode = ($event.keyCode ? $event.keyCode : $event.which);
        if ($keycode == 13) {
            // console.log("enter press");
            $(this).trigger("click");
            $event.stopPropagation();
        }
    });
}
