(function () {
    
    var COMMANDS = [
        { key: 'J', args: 1, handler: execJ },
        { key: 'JX', args: 2, handler: execJX },
        { key: 'C', args: 2, handler: execCX },
        { key: 'CX', args: 2, handler: execCX },
        { key: 'AX', args: 2, handler: execAX },
        { key: 'DialogPlain', args: 1, handler: execDialogPlain, async: true },
        { key: 'DialogHide', args: 0, handler: execDialogHide, async: true },
        { key: 'VO', args: 1, handler: execVO }
    ];

    function execJ(js) {
        eval(js);
    }

    function execJX(js, js2) {
        if (eval(js)) {
            eval(js2);
        }
    }

    function execCX(id, html) {
        $('#' + id).html(html);
    }

    function execAX(id, html) {
        $('#' + id).append(html);
    }
    
    function execDialogPlain(html, cb) {
        var $modal = $('<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="titleid" tabIndex="-1">' + html + '</div>');
        var $activeModal = $('.modal.show');

        $modal.appendTo(document.body);

        $modal.modal({ focus: true, keyboard: true, show: false })

        $modal.on('shown.bs.modal', cb);

        $modal.on('hidden.bs.modal', function() {
            $modal.remove();
        })

        if ($activeModal.length > 0) {
            $activeModal.modal('hide').on('hidden.bs.modal', function() {
                $modal.modal('show');
            });
        } else {
            setTimeout(function() {
                $modal.modal('show');
            });
        }

        /**
         * The focus trap behaviour seems to already be working, but I can't figure out why...
         * Perhaps there's some leftover code somewhere from when you were testing things earlier?
         * Have left my implementation below in case it's still required.
         */

        // function trapFocus(e) {
        //     if (!$modal.get(0).contains(e.target)) {
        //         $modal.trigger('focus');
        //     }
        // }

        // $modal.on('shown.bs.modal', function () {
        //     document.addEventListener('focusin', trapFocus);
        // });

        // $modal.on('hidden.bs.modal', function () {
        //     document.removeEventListener('focusin', trapFocus);
        // });
    }

    function execDialogHide(cb) {
        var $activeModal = $('.modal.show');

        if ($activeModal.length > 0) {
            $activeModal.modal('hide').one('hidden.bs.modal', cb);
        } else {
            cb();
        }
    }



    function execVO(html) {
        var $status = $('<div class="vo-status" aria-live="assertive" tabIndex="-1">' + html + '</div>');
        $status.appendTo(document.body);


        setTimeout(function() {
            $status.fadeOut(500, function() {
                $(this).remove();
            });
        }, 3000);

    }



    function getCommandForKey(key) {
        for (var i = 0; i < COMMANDS.length; i++) {
            if (COMMANDS[i].key == key) {
                return COMMANDS[i];
            }
        }

        return null;
    }

    function doCommand(command, args, cb) {
        if (command.async) {
            try {
                args.push(cb);
                command.handler.apply(this, args);
            } catch(e) {
                console.trace('Error while handling response from server', e.msg);
                cb();
            }
        } else {
            try {
                command.handler.apply(this, args);
            } catch(e) {
                console.trace('Error while handling response from server: command=' + command.key + ", " + JSON.stringify(args));
                console.error(e)
            }

            cb();
        }
    }

    function doNextCommand(arr, i) {
        if (i >= arr.length) {
            return;
        }

        var key = arr[i],
            command = getCommandForKey(key);
        
        if (!command) {
            console.error('Invalid command key', key);
            return doNextCommand(arr, i + 1);
        }
        
        var next = i + 1,
            args = arr.slice(next, next + command.args);
        
        doCommand(command, args, function() {
            doNextCommand(arr, next + command.args);
        });
    }

    window.v2 = function v2(arr) {
        if (!(arr instanceof Array)) {
            console.error('Response from server is not an array', arr);
            return;
        }

        doNextCommand(arr, 0);
    }

})();